/* * {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.nofoc {
  border: 0;
  width: 100%;
}

.nofoc:focus {
  border-radius: 0;
  border: 0;
}

/* .maplibregl-popup-content {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-radius: 0.5rem;
}

.maplibregl-popup-tip {
  border-top: 2px solid white;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
} */

.maplibregl-popup-content {
  background-color: #515151 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.maplibregl-popup-close-button {
  color: #ffffff !important;
}

#edit-button {
  background-color: #10171f !important;
  color: #ffffff;
  border: #ffffff 1px solid !important;
}

.Mui-disabled {
  color: #aaa !important;
}

/*Titles in admin*/
.css-174d3vs {
  align-items: baseline !important;
}

/*Resize add crash box*/
/*.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-2rpgvm-MuiPaper-root-MuiDrawer-paper {
  resize: horizontal !important; 
  overflow: auto; 
  min-width: 100px; 
  max-width: 600px; 
}*/

.maplibregl-popup-anchor-right .maplibregl-popup-tip {
  border-left-color: #515151 !important;
}

.maplibregl-popup-anchor-left .maplibregl-popup-tip {
  border-right-color: #515151 !important;
}

.maplibregl-popup-anchor-top .maplibregl-popup-tip {
  border-bottom-color: #515151 !important;
}

.maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
  border-top-color: #515151 !important;
}

.css-5i3tb5-MuiListItem-root {
  background-color: #515151 !important;
}

/*Login screen*/
/*Flag*/
.css-qm81nn {
  transform: scale(50%);
  /*aspect-ratio:*/
}

/*FRED logo*/
.css-1up9rcd {
  background-color: #ffffff;
  border-radius: 4px;
}

/*Input boxes*/
.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill,
.css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #5151519a inset !important;
}

/*Filter button*/
.css-h9yv4t button {
  margin-left: 5px !important;
  flex: 1;
}

.css-h9yv4t h5 {
  margin: 0 !important;
  flex: 10;
}

/*Crash data without borders*/
.css-ae6vcy-MuiCollapse-root-MuiTreeItem-groupTransition,
.css-r7ud6p-MuiCollapse-root-MuiTreeItem-groupTransition,
.css-k19xfo-MuiCollapse-root-MuiTreeItem-groupTransition,
.css-en5lsa-MuiCollapse-root-MuiTreeItem-groupTransition {
  box-shadow: none !important;
}

/*Data merging bottom margin*/
.css-dyunfp-MuiTypography-root {
  margin-bottom: 16px !important;
}